/* eslint-disable camelcase */
import { ComponentProps, FC, lazy, Suspense } from "react";
import { PremiumBanner } from "./uiParts/PremiumBanner";
import { useWorkWithShopWorkCassettes } from "./useWorkWithShopWorkCassettes";
import { PrimaryContentPostProps, SecondaryContentPostProps } from "./type";

const Presentation = lazy(() =>
  import("./Presentation").then((module) => ({ default: module.Presentation })),
);

type Props = {
  primaryContentAsync: PrimaryContentPostProps;
  secondaryContentAsync: SecondaryContentPostProps;
  login: boolean;
  premiumBanner?: ComponentProps<typeof PremiumBanner>;
};

export const WorkWithShopWorkCassettes: FC<Props> = ({
  primaryContentAsync: { url, params },
  secondaryContentAsync,
  login,
  premiumBanner,
}) => {
  const { works } = useWorkWithShopWorkCassettes(url, params, login, secondaryContentAsync);

  if (!works.length) return null;

  return (
    <Suspense fallback={null}>
      <Presentation works={works} premiumBanner={premiumBanner} />
    </Suspense>
  );
};
