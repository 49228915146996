// ga event送信系の処理は、GA4では不要ですが、GAUAが使える間は必要です。
// GA4に移行し切った場合は、不要になりますが、削除前に要不要の確認を必ずとってください。
// 他のjsファイルで行われているga event送信系処理に関しても同様です。
/* eslint-disable @typescript-eslint/no-explicit-any */
declare const GALoad: any;

type Props = {
  category: string;
  action: string;
  label?: string;
  value?: string;
};

export const sendGaEvent = (props: Props): void => {
  const { category, action, label, value } = props;
  if (typeof GALoad !== "undefined") {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    GALoad.loader().done((ga: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      ga("send", "event", category, action, label, value, { nonInteraction: true });
    });
  }
};
