import { FC } from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import * as styles from "./styles.css";

type Props = {
  width: string;
  spWidth?: string;
  label?: React.ReactElement;
  children?: React.ReactNode;
  /** コンポーネントに適用するクラス名 */
  className?: string;
  isContentsCenter?: boolean;
};

export const Th: FC<Props> = ({
  label,
  children,
  className,
  width,
  spWidth = "auto",
  isContentsCenter = false,
}) => (
  <th
    className={`
      ${styles.th}
      ${className || ""}
    `}
    style={assignInlineVars({
      [styles.width]: width,
      [styles.spWidth]: spWidth,
    })}
  >
    <span className={`${isContentsCenter ? styles.thInnerCenter : styles.thInner}`}>
      {children}
      {label && <span className={styles.labelContainer}>{label}</span>}
    </span>
  </th>
);
