import "app/packs/userFrontend/features/publication/components/DetailPageActionButtons/uiParts/DefaultStyleButtons/styles.css.ts.vanilla.css!=!../../../../../../../../../../../shared/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/packs/userFrontend/features/publication/components/DetailPageActionButtons/uiParts/DefaultStyleButtons/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71UW2+CMBR+91eclyWarIaL4oVk2T9ZKq3YDQspdeIW//ugomtp8bKHvRDo+S6nXw8dv/kF86dfkQ/fA4D3XSnZ+oCSnEvK5RKS+klFXJcIK4sMH5awzmjVLOCMpRwxSbflaRFRTpqCet8LXCyheTZLafPhB0VNPA7GZ89AeWo1zSUVTIntGZGbuu55TwY3VNy+6kRVN5Slm3oTk3nHeOooA+SCUFGLGdDI9ullhgZzdg9TZVVuBOMfS/AM/uI2X0Pja1sKtSQTnCXDqfcECKKiGhkiKyUiaSWROt3f89dAid6XpXZnOOTK0Z8GTANTVxKtbGAg1wq5xSJl/KQMeCfzTrI+rHZS5vwZtKVTgNaU98/fC7gyPw5et5QwDGUiKOWAOYHhlnHUCs2iGjZSxM60mqd11AHEBPgW4MqOznEgmRdqxBrqrS4Xc8/qMmjlrIg6V0F7Cxj9heYGgrhtrDobTiKvqOK+dh1ZdaahT6w13PGSSktr+oiWwYwu0V6An1gMEToDvFF8/cRmrYJxATDOJMNZbE2Do0dFXOGSlX3eCpGKfO/ynxvD0flX/hbKwowzDOYOEO7L/PH9XCKypyT5FxdiujjuwhsDSO0xuqTmoh5/AOWPn3OvBwAA\"}!../../../../../../../../../../../shared/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var acceptButtonWrapper = '_1pi15z67';
export var buttonMaxWidth = 'var(--_1pi15z60)';
export var buttonWrapper = '_1pi15z61';
export var deadlineWrapper = '_1pi15z6b';
export var declineButtonWrapper = '_1pi15z6a';
export var entryButtonWrapper = '_1pi15z63';
export var flexButtonWrapper = '_1pi15z62';
export var hasEntriedButtonWrapper = '_1pi15z69';
export var isScoutextendButtonWrapper = '_1pi15z6c';
export var justifyCenterButtonWrapper = '_1pi15z68';
export var keepButtonWrapper = '_1pi15z65';
export var lineConsultButtonOuter = _7a468({defaultClassName:'_1pi15z6e',variantClassNames:{hasMargin:{true:'_1pi15z6f'}},defaultVariants:{},compoundVariants:[]});
export var notScoutedSalonTourButtonWrapper = '_1pi15z64';
export var salonTourButtonWrapper = '_1pi15z66';
export var scoutExtendDeclineWrapper = '_1pi15z6d';