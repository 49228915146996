/* eslint-disable camelcase */
import { sendErrorLog } from "@root/utils/sendErrorLog";
import { ComponentProps, useCallback, useEffect, useState } from "react";
import { WorkWithShopWorkCassette } from "@userFrontend/features/publication/components/WorkWithShopWorkCassette";
import { WorkWithShopWorkCassetteProps } from "@userFrontend/features/publication/components/WorkWithShopWorkCassette/type";
import { PHONE_ENTRY_TEL_NUMBER } from "@root/utils/constants";
import { axiosPost } from "@root/utils/axiosForRails";
import { AxiosError } from "axios";
import {
  chooseSalaryUnit,
  omitEmploymentName,
  sliceSalaryPrice,
} from "@userFrontend/features/publication/utils/workWithShopWorkCassettes";
import {
  PrimaryContentPostProps,
  PrimarySearchHitCassettes,
  SecondaryContentPostProps,
} from "./type";

type ResponseProps = {
  data: PrimarySearchHitCassettes[];
};

export const useWorkWithShopWorkCassettes = (
  url: PrimaryContentPostProps["url"],
  params: PrimaryContentPostProps["params"],
  isLogin: boolean,
  secondaryContentAsync: SecondaryContentPostProps,
): { works: ComponentProps<typeof WorkWithShopWorkCassette>[] } => {
  const [worksData, setWorksData] = useState<PrimarySearchHitCassettes[]>([]);

  const fetchWorksData = useCallback(async () => {
    await axiosPost
      .post(url, params)
      .then((res: ResponseProps) => {
        setWorksData(res.data);
      })
      .catch((error: AxiosError) => {
        sendErrorLog(new Error(error.message || "求人カセットのデータを取得できませんでした"));
      });
  }, [url, params]);

  useEffect(() => {
    fetchWorksData();
  }, [fetchWorksData]);

  // 受け取ったデータを、使用するコンポーネントのpropsに合わせて整形する
  const formatData = useCallback(() => {
    if (!worksData.length) return [];

    const formattedData: WorkWithShopWorkCassetteProps[] = worksData.map((work) => {
      // 店舗指定求人や紐づく店舗が1店舗のみの場合は、GAラベルなどで使用するClientShopIdを指定する
      const clientShopId =
        work.hitShopsInformation.length === 1
          ? work.hitShopsInformation[0].clientShopId
          : undefined;
      const gaLabel = `${work.workCode}_${clientShopId || "null"}`;
      const latestAwardLabel = work.awardsLabels.sort((a, b) => b.year - a.year);

      const employmentAndSalaryType = work.salaries.map((salary) => ({
        employmentName: omitEmploymentName(salary.employmentTypeName),
        salaryMax: sliceSalaryPrice(salary.maxPrice),
        salaryMaxUnit: chooseSalaryUnit(salary.maxPrice),
        salaryMin: sliceSalaryPrice(salary.minPrice),
        salaryMinUnit: chooseSalaryUnit(salary.minPrice),
        salaryTypeName: salary.salaryTypeName,
      }));

      const shopInformations = work.hitShopsInformation.map((shopInformation) => ({
        detailUrlWithShopId: shopInformation.detailUrlWithShopId,
        name: shopInformation.name,
        prefecture: shopInformation.address.prefecture,
        city: shopInformation.address.city,
        detailAddress: shopInformation.address.detailAddress,
        detailNearestStation: shopInformation.address.station,
        shopId: shopInformation.clientShopId,
      }));

      // 店舗モーダルに店舗がまったく表示されない場合はnewrelicにエラー通知する
      if (work.hitShopsInformation.length === 0 && work.hitShopCount > 1) {
        sendErrorLog(new Error(`店舗を取得できませんでした URL：${window.location.href}`));
      }

      const formattedWork: WorkWithShopWorkCassetteProps = {
        workId: work.workId,
        clientShopId,
        statusLabelFlags: work.statusLabelFlags,
        imgSrc: work.imgSrc,
        shopOrBrandName:
          work.hitShopCount === 1 ? work.hitShopsInformation[0]?.name : work.brandName,
        businessTypeAndOccupation: `${work.businessType} / ${work.occupation}`,
        detailUrl: work.detailUrl,
        title: work.title,
        awardsLabel: latestAwardLabel.length
          ? {
              rank: latestAwardLabel[0].rank,
              children: latestAwardLabel[0].displayTextShort,
            }
          : undefined,
        isReviewLabel: work.hasReview,
        featureLabels: work.featureLabels,
        employmentAndSalaryType,
        isLogin,
        keepButtonUrl: work.actionContent.keep.url,
        hasKept: work.actionContent.keep.hasKept,
        enterableFlag: work.actionContent.entry.enterable,
        entriedFlag: work.actionContent.entry.hasEntered,
        scoutedFlag: work.actionContent.scoutApprove.scoutedFlag,
        scoutedEntryUrl: work.actionContent.scoutApprove.scoutedEntryUrl,
        scoutDeadline: work.actionContent.scoutApprove.scoutDeadlineDays,
        workCode: work.workCode,
        query: work.query,
        entryUrl: work.actionContent.entry.url,
        gaLabel,
        telNumberContent: {
          telNumber: PHONE_ENTRY_TEL_NUMBER,
          isShowTellButton: work.showTelNumber,
        },
        shopWorks: work.hitShopsInformation.map((shopInformation) => {
          const shopEmploymentAndSalaryType = shopInformation.salaries.map((shopSalary) => ({
            employmentName: omitEmploymentName(shopSalary.employmentTypeName),
            salaryMax: sliceSalaryPrice(shopSalary.maxPrice),
            salaryMaxUnit: chooseSalaryUnit(shopSalary.maxPrice),
            salaryMin: sliceSalaryPrice(shopSalary.minPrice),
            salaryMinUnit: chooseSalaryUnit(shopSalary.minPrice),
            salaryTypeName: shopSalary.salaryTypeName,
          }));

          return {
            sortNo: shopInformation.sortNo,
            clientShopId: shopInformation.clientShopId,
            imgSrc: work.imgSrc,
            url: shopInformation.detailUrlWithShopId,
            name: work.title,
            shopName: shopInformation.name,
            prefecture: shopInformation.address.prefecture,
            city: shopInformation.address.city,
            station: shopInformation.address.station,
            employmentAndSalaryType: shopEmploymentAndSalaryType,
            enterableFlag: shopInformation.actionContent.entry.enterable,
            entriedFlag: shopInformation.actionContent.entry.hasEntered,
            scoutedFlag: shopInformation.actionContent.scoutApprove.scoutedFlag,
            scoutedEntryUrl: shopInformation.actionContent.scoutApprove.scoutedEntryUrl,
            workCode: work.workCode,
            query: work.query,
            entryUrl: shopInformation.actionContent.entry.url,
          };
        }),
        clientShops: {
          totalNumber: work.hitShopCount,
          shopInformations,
        },
        secondaryContentAsync,
      };
      return formattedWork;
    });

    return formattedData;
  }, [worksData, isLogin, secondaryContentAsync]);

  return { works: formatData() };
};
