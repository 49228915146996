import { FC } from "react";
import { Presentation } from "./Presentation";
import { useWorkDetailBottomButtons } from "./useWorkDetailBottomButtons";

type Props = {
  fetchUrl: string;
  recommendFetchUrl: string;
  createLineConsultButtonHistoryUrl: string;
  isShowLineConsultButton: boolean;
};

export const WorkDetailBottomButtons: FC<Props> = ({
  fetchUrl,
  recommendFetchUrl,
  createLineConsultButtonHistoryUrl,
  isShowLineConsultButton,
}) => {
  const formattedData = useWorkDetailBottomButtons(
    fetchUrl,
    recommendFetchUrl,
    createLineConsultButtonHistoryUrl,
    isShowLineConsultButton,
  );
  return formattedData ? <Presentation {...formattedData} /> : null;
};
