import { sendErrorLog } from "@root/utils/sendErrorLog";
import { csrfToken } from "@root/utils/axiosForRails";
import { FetchJson } from "@root/utils/fetchJson";

type ReturnType = {
  status: boolean;
};

type PostParams = {
  // eslint-disable-next-line camelcase
  authenticity_token: string;
  code: string;
  // eslint-disable-next-line camelcase
  client_shop_id?: string;
};

export const useLineConsultButton = (): {
  handleClickLineConsultButton: (
    workCode: string,
    lineUrl: string,
    createLineConsultHistoryUrl: string,
    gaAction: string,
    clientShopId?: string | number, // 求人・ブランド・企業詳細ではnumber、店舗詳細ではstringが返ってくるため
  ) => void;
} => {
  const handleClickLineConsultButton = (
    workCode: string,
    lineUrl: string,
    createLineConsultHistoryUrl: string,
    gaAction: string,
    clientShopId?: string | number, // 求人・ブランド・企業詳細ではnumber、店舗詳細ではstringが返ってくるため
  ): void => {
    const authenticityToken = csrfToken();
    const params: PostParams = {
      // eslint-disable-next-line camelcase
      authenticity_token: authenticityToken,
      code: workCode,
    };
    // clientShopIdがある場合のみ、文字列にしてパラメータに追加する。
    if (clientShopId) {
      // eslint-disable-next-line camelcase
      params.client_shop_id = String(clientShopId);
    }

    // 発火させたユーザーを記録するため。RMの/line_button_historiesで確認可能。
    FetchJson<ReturnType>({
      url: createLineConsultHistoryUrl,
      params,
      method: "POST",
      handleError: (error?: Error) => {
        sendErrorLog(
          new Error(error?.message || "LINE相談ボタンを押したユーザーのPOSTに失敗しました。"),
        );
      },
    });
    window.open(lineUrl);
  };

  return { handleClickLineConsultButton };
};
