// 時給以外は万単位の表記にする
export const sliceSalaryPrice = (price: string) => {
  if (!price) return "";
  if (Number(price) < 10000) return price.toLocaleString();
  return (Math.ceil((Number(price) * 10) / 10000) / 10).toString();
};

// 雇用形態を給与表示用の省略した文字にする
export const omitEmploymentName = (employmentName: string) => {
  if (employmentName === "アルバイト・パート") return "ア";
  if (employmentName === "正社員") return "正";
  if (employmentName === "業務委託") return "委";
  if (employmentName === "契約社員") return "契";
  if (employmentName === "その他") return "他";
  return employmentName;
};

// 給与の単位を選択
export const chooseSalaryUnit = (price: string) => (Number(price) < 10000 ? "円" : "万円");
