import { ComponentProps, FC, MouseEventHandler } from "react";
import { Link } from "@root/shared/components/Link";

type Props = {
  query?: string;
  text: string;
  className?: string;
  size?: ComponentProps<typeof Link>["size"];
  // firebaseActionはwebviewでfirebaseイベント送信のために使用する。classNameに.firebase-clickがある場合にのみ機能する。
  firebaseAction?: string;
  url: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  gaAction?: string;
  gaLabel?: string;
};

export const EntryLink: FC<Props> = ({
  query,
  text,
  className,
  size,
  firebaseAction,
  url,
  onClick,
  gaAction,
  gaLabel,
}) => {
  return (
    <Link
      theme="primary"
      className={className}
      href={url}
      size={size}
      onClick={onClick}
      data-ga-action={gaAction}
      data-ga-label={gaLabel}
      data-query-str={query}
      {...(firebaseAction
        ? {
            "data-firebase-action": firebaseAction,
            "data-firebase-view-type": "screen_view",
          }
        : {})}
    >
      {text}
    </Link>
  );
};
