import { FC } from "react";
import { Button } from "@root/shared/components/Button";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import * as styles from "./styles.css";

type Props = {
  onClick?: () => void;
};

export const LineConsultButton: FC<Props> = ({ onClick }) => (
  <>
    <div className={styles.appealWrapper}>
      ＼<p className={styles.appealText}>求人探しにお悩み・サポート希望の方</p>／
    </div>
    <Button theme="lineWhite" onClick={onClick} className={styles.buttonWrapper}>
      <div className={styles.logoWrapper}>
        <LazyLoadImage src="/images/shared/LINE_Brand_icon.png" height={24} width={24} />
      </div>
      <p className={styles.buttonTextWrapper}>リジョブエージェントに相談</p>
    </Button>
  </>
);
