import { FC } from "react";
import { useLineConsultButton } from "@userFrontend/hooks/useLineConsultButton";
import { AGENT_LINE_URL } from "@root/utils/constants";
import { LineConsultButton } from "@userFrontend/components/Buttons/LineConsultButton";
import {
  KeepButton,
  KeepButtonProps,
} from "@legacy_user_frontend/components/ui_parts/buttons/keep_button";
import * as styles from "./styles.css";

type Props = {
  workCode: string;
  createLineConsultButtonHistoryUrl: string;
  clientShopId: string | number; // 求人詳細ではnumber、店舗詳細ではstringが返ってくるため
  isShowLineConsultButton: boolean;
} & KeepButtonProps;

export const NotEnterableWorkButtons: FC<Props> = ({
  url,
  query,
  gaAction,
  gaLabel,
  reproAction,
  workCode,
  hasKeeped,
  createLineConsultButtonHistoryUrl,
  clientShopId,
  isShowLineConsultButton,
}) => {
  const { handleClickLineConsultButton } = useLineConsultButton();
  return (
    <>
      <div className={styles.wrapper}>
        <KeepButton
          url={url}
          workCode={workCode}
          hasKeeped={hasKeeped}
          query={query}
          gaAction={gaAction}
          gaLabel={gaLabel}
          reproAction={reproAction}
        />
        {isShowLineConsultButton && (
          <div className={styles.lineButtonWrapper}>
            <LineConsultButton
              onClick={() =>
                handleClickLineConsultButton(
                  workCode,
                  AGENT_LINE_URL,
                  createLineConsultButtonHistoryUrl,
                  "not_enterable-line", // data-ga-action
                  clientShopId,
                )
              }
            />
          </div>
        )}
      </div>
    </>
  );
};
